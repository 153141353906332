import Link from "next/link";
import Image from "next/image";
import { NextSeo } from "next-seo";
import Layout from "@/components/layout";

export default function Custom404() {
  return (
    <Layout>
      <NextSeo
        title="Page not found - IS Audtir"
        description="Meet IS Auditr: Leading consultancy in ISO standards. The company has helped over 200+ organisations in developing quality management systems."
      />

      <div
        data-elementor-type="error-404"
        data-elementor-id="1387"
        className="elementor elementor-1387 elementor-location-single"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-2e181df elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="2e181df"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e774061"
              data-id="e774061"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-225b0b7 elementor-widget elementor-widget-heading animated fadeInUp"
                  data-id="225b0b7"
                  data-element_type="widget"
                  data-settings='{"_animation":"fadeInUp"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h5 className="elementor-heading-title elementor-size-default">
                      404 page
                    </h5>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-1dda47a elementor-widget elementor-widget-heading"
                  data-id="1dda47a"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h1 className="elementor-heading-title elementor-size-default">
                      not found
                    </h1>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-ed975e2 elementor-widget elementor-widget-text-editor"
                  data-id="ed975e2"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    We don’t know how you got here, but you have landed on the
                    wrong page.
                    <br />
                    Don’t worry, though — you can go back or use the navigation
                    above to find your way.
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7e3ef7f elementor-align-center elementor-widget elementor-widget-button"
                  data-id="7e3ef7f"
                  data-element_type="widget"
                  data-widget_type="button.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-button-wrapper">
                      <Link
                        href="/"
                        title="Home"
                        role="button"
                        rel="dofollow"
                        className="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                      >
                        <span className="elementor-button-content-wrapper">
                          <span className="elementor-button-text">
                            back to home
                          </span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-2b10462 elementor-widget elementor-widget-image"
                  data-id="2b10462"
                  data-element_type="widget"
                  data-widget_type="image.default"
                >
                  <div className="elementor-widget-container">
                    <Image
                      src="/images/404Page.webp"
                      className="attachment-full size-full"
                      alt="About IS Auditr"
                      loading="lazy"
                      width="1726"
                      height="1150"
                      sizes="100vw"
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
